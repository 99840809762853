import 'magnific-popup';
import axios from 'axios';
import initPopup, { openDynamicPopup } from '../popup/popup';
import { GAME_IMG_DEF } from '../../../../js/constants';

/**
 * Convert attributes from JSON to string
 * @param {{}} obj - Attributes
 * @return {string} - Attributes for markup
 */
function getAllAttributes(obj) {
    let str = '';
    for (const prop in obj) {
        if( obj.hasOwnProperty( prop ) ) {
            str += `${prop}="${obj[prop]}" `
        }
    }
    return str;
}

/**
 * Check attributes presence for game
 * @param {{}} game - Game object
 * @param {string} [imgType = 'logo'] - Type of image
 * @return {{}} - Attributes in JSON
 */
function checkAttributes(game, imgType) {
    if (game.images[imgType] &&
        game.images[imgType].attributes &&
        !$.isEmptyObject(game.images[imgType].attributes)) {
        return game.images[imgType].attributes;
    }

    return {'alt': game.name};
}

/**
 * Check image of given type exist
 * @param {{}} game - Game object
 * @param {string} [imgType = 'logo'] - Type of image
 * @return {boolean} - is image of given type exist
 */
function checkImageExist(game, imgType) {
    return (
        !$.isEmptyObject(game.images) &&
        !$.isEmptyObject(game.images[imgType]) &&
        game.images[imgType].link !== ''
    )
}

/**
 * Add and remove game to/from favorite
 */
localStorage.removeItem('searchID');

function toggleGameFavotite(toggler) {
  function changeGame(isFav) {
    toggler.setAttribute('data-infavorite', isFav);
    var title = 'Избранное';
    var addedToFav;
    if (!isFav){
      removeGame(toggler);
      addedToFav = ' удалена из избранного';
      toggler.classList.remove('fa-star-full');
    }
    else{
      toggler.classList.add('fa-star-full');
      cloneGame(toggler);
      addedToFav = ' добавлена в избранное';
    }
    var message = 'Игра '+toggler.dataset.name+addedToFav;
    openDynamicPopup(title, message);
    initPopup();
  }

  function removeGame(game) {
    //remove from favorite tab
    $('#favourite-games').find('.fa[data-code="'+game.dataset.code+'"]').closest('.game').remove();
    //not full all stars on page
    $('.fa[data-code="'+game.dataset.code+'"]').removeClass('fa-star-full').attr('data-infavorite', 'false');
  }

  function cloneGame(game) {
    $('#favourite-games .fav-list-empty').remove();
    var clonedGame = $(game).closest('.game').clone(true).appendTo('#favourite-games .game-halls-wrapp');
    clonedGame.find('.fa-star-empty').removeClass('event-fav');
    $('.fa[data-code="'+game.dataset.code+'"]').addClass('fa-star-full').attr('data-infavorite', 'true');
    onFavourClick(); //add event to new element
  }

  const sendingData = {};
  sendingData.code = toggler.dataset.code;
  sendingData.provider = toggler.dataset.prov;
  const isFavorite = toggler.dataset.infavorite;
  if (isFavorite === 'true') {
      axios.post('/service/player/game/remove', sendingData)
          .then(response => response.data)
          .then(res => res.data ? changeGame(false) : openDynamicPopup('Ошибка', res.error.items[Object.keys(res.error.items)[0]]))
          .catch(error => alert(error));
  } else {
      axios.post('/service/player/game/add', sendingData)
          .then(response => response.data)
          .then(res => res.data ? changeGame(true) : openDynamicPopup('Ошибка', res.error.items[Object.keys(res.error.items)[0]]))
          .catch(error => alert(error));
  }
}

function getGameLink(mode, gameCode, gameProvider){
  axios.get('/service/game/'+mode+'/'+gameCode+'/'+gameProvider+'/html5/null')
      .then(({ data: { data: gameLink } }) => {
        if (gameLink)
          setTimeout(() => {
              location.href = gameLink;
          }, 100);

      })
}

export function onFavourClick() {
    const stars = [...document.querySelectorAll('.fa-star-empty:not(.event-fav)')];

    stars.forEach((star) => {
        star.addEventListener('click', (e) => {
            e.preventDefault();
            toggleGameFavotite(star);
        });
        star.classList.add('event-fav');
    });
}

export function openGamePopup() {
    $(document).ready(() => {
        $('.game-popup-link').magnificPopup({
            type: 'inline',
            midClick: true,
            items: {
              src: '#game-popup'
            },
            mainClass: 'mfp-fade',
            showCloseBtn: false,
            fixedBgPos: true,
            callbacks: {
                open: function() {
                    document.body.classList.add("modal-opened")
                    var gameCode = $(this.st.el).closest('.game__wrapp').attr('data-code');
                    var gameProvider = $(this.st.el).closest('.game__wrapp').attr('data-prov');
                    var rulesLink = `/game/${window.userLogged ? 'real' : 'demo'}/${gameProvider}/${gameCode}`;
                    var gameName = $(this.st.el).closest('.game__wrapp').attr('data-name');

                    if(userLogged){
                        $('#gamePopup_moneyLink').prop('href', '#');
                    }
                    else {
                        $('#gamePopup_moneyLink').prop('href', '/registration').removeClass('play-game-btn').text('Регистрация');
                    }
                    $('.play-game-btn').attr('data-code', gameCode).attr('data-prov', gameProvider);
                    $('#gamePopup_rulesLink').prop('href', rulesLink);
                    $('#gamePopup_gameName').text(gameName);
                },
                close: function() {
                    document.body.classList.remove("modal-opened")
                },
            }
        });
    });
}

export function setPlayButtons() {
  $(document).on('click', '.play-game-btn', (e) => {
    const button = e.currentTarget;
    const gameCode = button.dataset.code;
    const gameMode = button.dataset.mode;
    const gameProvider = button.dataset.prov;
    if (gameMode !== 'null'){
      e.preventDefault();
      getGameLink(gameMode, gameCode, gameProvider);
    } else {
      location.href = button.href;
    }
  });
}

export function initGame() {
  openGamePopup();
  onFavourClick();
  setPlayButtons();
}

function notMobileGame() {
    return (`<div class="not-mobile">
                <p>Игра доступна в полной версии сайта</p>
            </div>
            `)
}

export function renderGame(game) {
  const imgPath = checkImageExist(game, 'logo')
    ? game.images.logo.link
    : GAME_IMG_DEF;
  const attributes = getAllAttributes(checkAttributes(game, 'logo'));
  const favButton = userLogged ? `<i data-code="${game.code}" 
                                    data-prov="${game.providerCode}" 
                                    data-infavorite="${game.favorite ? 'true' : 'false'}" 
                                    data-name="${game.name}" 
                                    class="fa fa-star-empty ${game.favorite ? 'fa-star-full' : ''}"
                                    >
                                  </i>`
                                : '';
  const prizeData = `<div class="bank-data">
                       ${favButton}
                       <div class="bank-data__item">
                           <i class="icon digit fa fa-bank"></i>
                           <span class="bank__sum digit">${parseInt(game.points, 10).toLocaleString('ru') || ''}</span>
                       </div>
                       <div class="bank-data__item record">
                           <i class="icon fa fa-crown"></i>
                           <span class="record__sum record__sum_game">${parseInt(game.record, 10).toLocaleString('ru') || ''}</span>
                       </div>
                     </div>`;

  const outTournData = `<div class="bank-data">
                        ${favButton}
                        <p class="bank-data__text">Не участвует в турнире</p>
                   </div>`;

  return $(`<div class="game">
                          ${game.mobile ? '' : notMobileGame()}
                          <div data-name="${game.name}" data-code="${game.code}" data-prov="${game.providerCode}" data-infavorite="${game.favorite ? 'true' : 'false'}" class="game__wrapp">
                          <a href="#game-popup" class="game-popup-link">
                              <img src="${imgPath}" ${attributes}>
                          </a>
                          <div class="game__header">
                              <a href="#game-popup" class="game__title game-popup-link">${game.name}</a>
                              ${game.points || game.record ? prizeData : outTournData}
                          </div>
                          </div>
                      </div>`);
}
