import 'slick-carousel';
import 'magnific-popup';
import { onInputFocus, onInputTooltip, togglePasswordType } from '../../blocks/modules/_common/form/form';
import initPopup, { openDynamicPopup } from '../../blocks/modules/_common/popup/popup';
import onChangePassSubmit from '../../blocks/modules/_common/form/_change-pass/form_change-pass';
import { initGame } from '../../blocks/modules/_common/game/game';
import { getUrlParameter, localStorageOpearate } from '../utils';

function checkParams() {
    const forgot = getUrlParameter('forgotCode');
    $('#forgotCode_input').val(forgot);
    if (forgot && forgot !== '') {
        $.magnificPopup.open({
            items: {
                src: '#change-pass',
            },
        });
    }

    switch (getUrlParameter('emailVerification')) {
        case '': {
            break;
        }
        case 'ok': {
            openInfoPopup('Успешно', 'Ваш email подтвержден');
            break;
        }
        case 'no': {
            openInfoPopup('Ошибка', 'Подтвердить email не удалось');
            break;
        }
    }

    if (window.userLogged) {
        switch (getUrlParameter('transaction')) {
            case '': {
                break;
            }
            case 'ok': {
                openInfoPopup('Поздравляем!', 'Средства зачислены на ваш игровой счет');
                break;
            }
            case 'no': {
                openInfoPopup('Ошибка', 'Обратитесь в службу поддержки');
                break;
            }
        }
    }

  const passwordChanged = localStorageOpearate('passwordChanged');
  if (passwordChanged && passwordChanged !== '') {
    openDynamicPopup('Успешно!', 'Пароль успешно изменен');
    localStorageOpearate('passwordChanged', 'remove');
  }
}

$(document).ready(() => {
    $('.main-slider').slick({
        responsive: true,
        dots: true,
        prevArrow: '<i class="fa fa-angle-left"></i>',
        nextArrow: '<i class="fa fa-angle-right"></i>',
    });
});

onChangePassSubmit();
onInputFocus();
onInputTooltip();
togglePasswordType();
initPopup();
checkParams();
initGame();

// #25109: fix wrong slide width on init
setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 0);
