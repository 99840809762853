import { findAncestor } from '../../../../js/utils';
import 'dom-fader';

const onInputFocus = () => {
    const inputs = [...document.querySelectorAll('input, select, textarea')];

    inputs.forEach((input) => {
        const formBlock = findAncestor(input, 'form-block');
        let label;
        if (formBlock) {
            label = formBlock.querySelector('.form-block__label');
        } else {
            return
        }

        if (label) {
            input.addEventListener('focus', () => {
                label.classList.add('form-block__label_active');
            });
            input.addEventListener('blur', () => {
                label.classList.remove('form-block__label_active');
            });
        }
    });
};

const onInputTooltip = () => {
        const inputs = [...document.querySelectorAll('.form-block__input_tooltip')];
        const tooltips = [...document.querySelectorAll('.form-block__help')];

        inputs.forEach((input) => {
            input.addEventListener('focus', () => {
                tooltips.forEach(tooltip => tooltip.style.display = 'none');
                $(input).parent().siblings('.form-block__error').hide();
                $(input).parent().siblings('.form-block__help').show();
            });
            input.addEventListener('blur', function () {
                $(input).parent().siblings('.form-block__error').hide();
                tooltips.forEach(tooltip => { tooltip.fadeOut(200) });
            });
        });
};


const validateRules = () => {
    const rulesCheckers = [...document.querySelectorAll('input[id^="rules-check"]')];

    rulesCheckers.forEach((rulesCheck) => {
        rulesCheck.addEventListener('change', () => {
            const rulesError = document.createElement('span');
            rulesError.className = 'form-block__error form-block__error_rules';
            rulesError.textContent = 'Вы должны согласиться с правилами казино';
            if (rulesCheck.checked) {
                rulesCheck.classList.remove('form-block__checkbox_error');
                $('.form-block__error_rules').remove();
            } else {
                rulesCheck.classList.add('form-block__checkbox_error');
                rulesCheck.parentNode.appendChild(rulesError);
            }
        });
    })
};

const togglePasswordType = () => {
    const eyeBtns = [...document.querySelectorAll('.fa-eye')];

    eyeBtns.forEach(btn => btn.addEventListener('click', function () {
            const input = this.parentNode.querySelector('.form-block__input');

            if (input.getAttribute('type') === 'text') {
                input.setAttribute('type', 'password');
            } else {
                input.setAttribute('type', 'text');
            }
            input.focus();
            this.classList.toggle('fa-eye-hide');
        })
    )
};

const hideNavBar = () => {
    const inputs = [...document.querySelectorAll('form input')];

    inputs.forEach((input) => {
        const navBar = document.querySelector('.nav-tabs');

        input.addEventListener('focus', function() {
            navBar.style.display = 'none';
        })
        input.addEventListener('blur', function() {
            navBar.style.display = 'block';
        })
    })
}

export {
    validateRules,
    togglePasswordType,
    onInputFocus,
    onInputTooltip,
    hideNavBar,
};
