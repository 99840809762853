import {openDynamicPopup} from '../popup/popup';
import {loadCaptcha} from '../form/__captcha/form__captcha';
import { findAncestor } from '../../../../js/utils';
import { URL_AUTH } from '../../../../js/constants';

export function createValidationMessages(object, error, message) {
    if (!error) {
        $(object).addClass('form-block__input_success');
        $(object).after('<span class="form-block__success">' + message + '</span>');
    } else {
        $(object).addClass('form-block__input_error');
        $(object).after('<span class="form-block__error">' + message + '</span>');
    }
}

export function validateLogin(login) {
    if (login.value.length === 0) {
        showErrorMessages($(`#${login.id}`), 'Укажите email');
        return false;
    }

    return true;
}

function isPasswordNotEmpty(password) {
    if (password.value.length === 0) {
        showErrorMessages(
            $(`#${password.id}`),
            'Укажите пароль'
        );
        return false;
    }

    return true;
}

function isPasswordValid(password) {
    const valid = /^.{8,}$/.test(password.value);

    if (!valid) {
        showErrorMessages(
            $(`#${password.id}`),
            'Пароль не менее 8 символов: цифры и буквы в английской раскладке клавиатуры.'
        );
        return false;
    }

    return true;
}

export function validatePhone(phone) {
    const pattern = /^[0-9]*$/;

    if (!phone.value || phone.value.length === 0) {
        showErrorMessages($(`#${phone.id}`), 'Укажите номер телефона');
        return false;
    }

    if (!pattern.test(phone.value)) {
        showErrorMessages($(`#${phone.id}`), 'Укажите корректный номер');
        return false;
    }

    return true;
}

export function validatePhonePassword(password) {
    if (!password.value || password.value.length === 0) {
        showErrorMessages($(`#${password.id}`), 'Введите пароль');
        return false;
    }

    return true;
}

export const validatePassword = (password, type) =>
   type ? isPasswordNotEmpty(password) && isPasswordValid(password) : isPasswordNotEmpty(password);

export function hideAllErrorMessage() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__error').hide();
}

export function clearValidationMessages() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__error').remove();
    $('.form-block__input_success').removeClass('form-block__input_success');
    $('.form-block__success').remove();
}

export function showErrorMessages(object, message) {
    const input = object[0] || object;
    input.classList.add('form-block__input_error')
    const formBlock = findAncestor(input, 'form-block');
    const helpContainer = formBlock.querySelector('.form-block__help');
    const errorContainer = formBlock.querySelector('.form-block__error');
    errorContainer.textContent = message;
    errorContainer.style.display = 'block';
    if (helpContainer) {
        helpContainer.style.display = 'none';
    }
}

export function showCaptcha() {
    loadCaptcha();
    $('.form-block_captcha').show().removeClass('form-block_hidden');
}

export function loginAjax(loginData, autoLogin, isUserHasCoupon, phone) {
    const loginBtns = [...document.querySelectorAll('.btn_auth')]

    loginBtns.forEach((btn) => btn.disabled = true)
    $.ajax({
       type: 'POST',
       url: URL_AUTH,
       data: JSON.stringify(loginData),
       dataType: "json",
       contentType: "application/json",
       headers: {
           'X-Requested-With': ''
       },
       success: function ({ data, error }) {
           if (data.loginSuccess) {
               if (isUserHasCoupon) {
                   location.replace('/deposit');
               } else {
                   location.replace('/');
               }
           } else {
               if (autoLogin) {
                   location.href = '/login?email=' + loginData.login;
               }
               else {
                   $.each(error.items, function (i, val) {
                       if (i === 'captcha') {
                           loadCaptcha();
                           $('.form-block_captcha').show();
                           $(`#captcha`).addClass('form-block__input_error');
                           showErrorMessages($(`#captcha`), val);
                       }
                       else {
                           if (phone) {
                               $('#login-phone').addClass('form-block__input_error');
                               $('#password-phone').addClass('form-block__input_error');
                               showErrorMessages($('#password-phone'), val);
                           }
                           else {
                               $('#login').addClass('form-block__input_error');
                               $('#password').addClass('form-block__input_error');
                               showErrorMessages($('#password'), val);
                           }
                       }
                   });

                   if (data.isNeedCaptcha) {
                       showCaptcha();
                   }
               }
           }

           loginBtns.forEach((btn) => btn.disabled = false)
       },
       error: function (request, status, error) {
           openDynamicPopup('Сбой сервера', 'Сбой сервера, попробуйте позже');
           loginBtns.forEach((btn) => btn.disabled = false)
       },
   });
}

function showSuccessRegistrationMessage() {
    const email = location.search.split('email=')[1];
    if (email !== undefined) {
        $(`#login`).val(email);
        openDynamicPopup('Регистрация', 'Регистрация прошла успешно. Необходимо выполнить вход.');
    }
}

showSuccessRegistrationMessage();
