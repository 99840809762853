function loadCaptcha() {
    var captcha = new Image();
    var param = Math.random().toString(36).slice(-5);
    captcha.src = '/service/captcha?rnd=' + param;
    captcha.className = 'form-block__captcha-img';
    captcha.alt = 'captcha';
    captcha.id = 'captcha-img';
    captcha.width = 144;
    captcha.height = 36;
    $('.captchaContainer').html(captcha);
}

export {loadCaptcha};
