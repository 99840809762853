import "magnific-popup";

export default function initPopup() {
    $(document).ready(() => {
        $(".popup-link").magnificPopup({
            type: "inline",
            midClick: true,
            mainClass: "mfp-fade",
            showCloseBtn: false,
            fixedBgPos: true,
            callbacks: {
                open: () => document.body.classList.add("modal-opened"),
                close: () => document.body.classList.remove("modal-opened")
            }
        });
        onClosePopupClick();
    });
}

export function onClosePopupClick() {
    $(document).on("click", ".popup__link_close", e => {
        e.preventDefault();
        $.magnificPopup.close();
    });
}

export function openDynamicPopup(title, text, params) {
    $.magnificPopup.open({
        items: {
            src:
                '<div class="popup"><h3 class="popup__h3">' +
                title +
                "</h3><p>" +
                text +
                '</p><div class="popup__btns popup__btns_single"><span class="popup__link popup__link_close">Закрыть</span></div></div>',
            type: "inline"
        },
        closeBtnInside: true,
        showCloseBtn: false,
        callbacks: {
            close: () => {
                if (params) {
                    params.reload && window.location.reload();
                    if (params.redirect) {
                        window.location.href = params.redirect;
                    }
                }
                document.body.classList.remove("modal-opened");
            },
            open: () => {
                onClosePopupClick();
                document.body.classList.add("modal-opened");
            }
        }
    });
}
