import { get, post } from 'axios';
import { URL_RESOURCES_PREFIX, URL_RESOURCES_SUFFIX, LIVETEX_IDS } from './constants';
import formDataEntries from 'form-data-entries';

function serializeForm($) {
  $.fn.serializeFormJSON = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
  };
}

/**
 * Format date from milliseconds to DD.MM.YY
 * @param {number} ms
 * @returns {string} formatted value
 */
export function formatDateDDMMYY(ms) {
    const date = new Date(ms);
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let h = date.getHours();
    let min = date.getMinutes();

    m = m < 10 ? `0${m}` : m;
    d = d < 10 ? `0${d}` : d;
    h = h < 10 ? `0${h}` : h;
    min = min < 10 ? `0${min}` : min;

    return `${d}.${m}.${y}`;
}

export function handleContentToggle(deltaHeight) {
    $('.toggle-block').click(function click() {
        const items = $(this);
        const $content = $(this).next();
        const blockClass = this.className.split('__')[0];
        const headerHeight = $('.main-header').height() + deltaHeight;

        if ($content.is(':visible')) {
            $(`.${blockClass}__content`).slideUp('fast').removeClass(`${blockClass}__content_active`);
            $content.slideUp('fast').removeClass(`${blockClass}__content_active`);
        } else {
            $(`.${blockClass}__content`).slideUp('fast').removeClass(`${blockClass}__content_active`);
            $content.slideDown('fast').addClass(`${blockClass}__content_active`);
        }

        setTimeout(() => {
            $('html, body').animate({
                scrollTop: ($(items).first().offset().top - headerHeight),
            }, 500);
        }, 200);
    });
}

function initContentToggler(deltaHeight = 16) {
    document.addEventListener('DOMContentLoaded', () => handleContentToggle(deltaHeight));
}

/**
 * Beautify single value name by amount
 * @param {array} number - value to be beautified
 * @param {array} titles - names attached to values
 */
function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

/**
 * Beautify value names by amount
 * @param {array} values - values to be beautified
 * @param {array} valueNames - names attached to values
 */
function renderValueNames(values, valueNames) {
    const valuesList = [...values];
    valuesList.forEach((item) => {
        const value = item.textContent;
        const valueRendered = declOfNum(Number(value), valueNames);
        const newItem = item;
        newItem.textContent = value + ' ' + valueRendered;
    });
}

/**
 * Search through parents by className
 * @param {HTMLElement} el - element for Search
 * @param {string} cls - class for search
 */
function findAncestor(el, cls) {
    let elem = el;
    while ((elem = elem.parentElement) && !elem.classList.contains(cls));
    return elem;
}

/**
 * Convert phone number from InputMasked plugin
 * @param {string} phone
 */
export const convertPhone = phone => `${phone.replace(/[(\)-]/g, '')}`;

/**
 * Get random integer number
 * @param {number} min - minimal random value
 * @param {number} max - maximal random value
 */
export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function localStorageOpearate(paramName, type = 'get', paramValue) {
    switch (type) {
    case 'get':
        var gettedInfo = localStorage.getItem(paramName);
        return gettedInfo;
        break;
    case 'set':
        localStorage.setItem(paramName, paramValue);
        return false;
        break;
    case 'remove':
        localStorage.removeItem(paramName);
        return false;
        break;

    default:
        return false;
    }
}

/**
 * Get scope for resources
 * @returns {Promise<obj>}
 */
export const getResources = async (scope) => {
    try {
        const url = URL_RESOURCES_PREFIX + scope + URL_RESOURCES_SUFFIX;
        const { data } = await get(url);
        return data;
    } catch (err) {
        console.log(err.message);
    }
}


/**
 * Get LiveTex ID
 * @param {number} id - site clone ID
 * @return {number} - LiveTex ID
 */
export const getLiveTexId = (id = 1) => LIVETEX_IDS[id];

export class Form {
    /**
     * Form constructor
     * @param { HTMLElement } elem - The form
     * @param { Boolean } isValid - Is the form valid
     * @param { HTMLElement } submitBtn - Button which submit the form
     * @param { Object } patterns - RegExp for validation
     */
    constructor(elem, submitBtn, isValid = false, patterns) {
        this.elem = elem;
        this.isValid = isValid;
        this.submitBtn = submitBtn || this.elem.querySelector('button');

        /**
         * RegExp for fields validation
         * @type {{digits: RegExp, letters: RegExp, email: RegExp, password: Regexp, qiwi: RegExp, visa_rus: RegExp, visa_rus_holder: RegExp, visa_uah_holder: RegExp, yandexmoney: RegExp, ecommpay: RegExp, webmoney: RegExp}}
         */
        this.patterns = {
            digits: /^\d+$/,
            letters: /[a-zA-Z]{2,}$/,
            email: /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([A-Za-z0-9_-]+\.)+[A-Za-z0-9]([A-Za-z0-9-])+$/i,
            password: /[\s\S]{6,120}/,
        };

        this.errorMessages = {
            name: 'Укажите имя',
            email: 'Неверный email',
            message: 'Поле с сообщением пустое',
        };

        this.addListeners();
    }

    addListeners() {
        let fieldsColl = this.elem.querySelectorAll('input:not([type="hidden"]):not(.unrequired)');
        let fields = [];
        let checkboxes = [];
        let rulesCheck = document.querySelector('#rules-check');

        rulesCheck.addEventListener('change', () => {
            let rulesError = document.createElement('span');
            rulesError.className = 'form-block__error form-block__error_rules';
            rulesError.textContent = 'Вы должны согласиться с правилами казино';
            if (rulesCheck.checked) {
                rulesCheck.classList.remove('checkbox-wrapp__checkbox_error');
                this.submitBtn.removeAttribute('disabled');
                $('.form-block__error_rules').remove();
            } else {
                rulesCheck.classList.add('checkbox-wrapp__checkbox_error');
                rulesCheck.parentNode.appendChild(rulesError);
                this.submitBtn.setAttribute('disabled', 'disabled');
            }
        });

        for (let field of fieldsColl) {
            if (field.type === 'checkbox') {
                checkboxes.push(field);
                this.validateCheckboxes(checkboxes);

                field.addEventListener('change', () => {
                    this.validateCheckboxes(checkboxes);
                });
            } else {
                let fieldProto = Object.assign({}, field.dataset);
                fieldProto.valid = false;
                fieldProto.value = field.value;
                fields.push(fieldProto);

                field.addEventListener('input', () => {
                    fieldProto.value = field.value;
                    this.validateInputs(fields);
                });

                field.addEventListener('paste', () => {
                    fieldProto.value = field.value;
                    this.validateInputs(fields);
                });
            }
        }
    }

    /**
     * @param { Array } fields - Form inputs
     */
    validateInputs(fields) {
        fields.forEach(field => {
            let pattern = this.patterns[field.type] || /[\s\S]/;
            field.valid = pattern.test(field.value);
        });

        this.isInputsValid = fields.every(field => field.valid);
        this.validateAll();
    }

    validateCheckboxes(inputs) {
        this.allChecked = false;
        if (inputs.length === 0) {
            return this.allChecked = true;
        } else {
            this.allChecked = inputs.every(input => input.checked);
            this.validateAll();
        }
    }

    validateAll() {
        this.isValid = this.isInputsValid && this.allChecked;
    }
}

const simpleHandleError = error => console.log(error);

/**
 * Insert HTML element after another
 * @param elem Element which insert
 * @param refElem Element after with insert
 */
export const insertAfter = (elem, refElem) => refElem.parentNode.insertBefore(elem, refElem.nextSibling)

/**
 * Check the input character is number
 * @param {number} key - keyCode
 * @returns {boolean}
 */
export const onlyNumbersTyped = key => (key >= 46 && key <= 57) || (key >= 96 && key <= 105) || key === 8;

/**
 * Show error message from server on ajax call
 * @param { String } name Invalid input's name
 * @param { String } text Error message text
 */
export const showErrorMessage = (name, text, form) => {
    const input = document.querySelector(`input[name="${name}"]`)
    if (input) {
        input.classList.add('form-block__input_error', 'js-invalid')
        const messageContainer = document.createElement('span')
        messageContainer.classList.add('form-block__error')
        messageContainer.textContent = text
        insertAfter(messageContainer, input.nextSibling)
    } else {
        if (!form) {
            console.log('Error', name, text)
            return
        }
        const button = form.querySelector(`.btn-wrapp`);
        button.insertAdjacentHTML('beforebegin', `<span class="form-block__error">${text}</span>`);
    }
}

/**
 *
 * @param { Boolean } success Is operation successful or failed
 * @param { String } text Message text
 * @param { String } [title] Title for panel
 */
export const showInfoPanel = (success, text, title = null) => {
    const panel = $('.info-panel')
    const panelTitle = $('.info-panel__title')
    const panelText = $('.info-panel__text')

    if (success) {
        panel.addClass('info-panel_success active')
        panelTitle.text(title || 'Успешно!')

        $('.info-panel__close').click(function (e) {
            e.preventDefault()
            window.location = '/cashier'
        })
    } else {
        panel.addClass('info-panel_error active')
        panelTitle.text(title || 'Что-то пошло не так')

        $('.info-panel__close').click(function (e) {
            e.preventDefault()
            $('.info-panel').removeClass('active')
        })
    }

    panelText.html(text)
}

export function disableFormButton(form, disabled){
    $(form).find('button').prop('disabled', disabled);
}

export function clearValidationMessages() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__error').remove();
    $('.form-block__input_success').removeClass('form-block__input_success');
    $('.form-block__success').remove();
}

export { serializeForm, initContentToggler, declOfNum, renderValueNames, findAncestor, simpleHandleError };

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

/**
 * Working with cookies
 * @type {{set: ((name, value, days?)), get: ((name)), delete: ((name))}}
 */
export const cookieUtils = {
    set(name, value, days) {
        let expires;
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toGMTString()}`;
        } else {
            expires = '';
        }
        document.cookie = `${name}=${value + expires}; path=/`;
    },
    get(name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    delete(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    },
};

export const handleTabSelect = (label, isNeedCaptchaReg) => {
    switch (label) {
        case 'reg_phone':
            if (isNeedCaptchaReg) document.querySelector('.captcha-reg').classList.remove('hidden')
            document.getElementById('form_reg_mail').classList.toggle('hidden');
            document.getElementById('form_reg_phone').classList.toggle('hidden');
            break;
        case 'reg_mail':
            if (isNeedCaptchaReg) document.querySelector('.captcha-reg').classList.add('hidden')
            document.getElementById('form_reg_mail').classList.toggle('hidden');
            document.getElementById('form_reg_phone').classList.toggle('hidden');
            break;

        case 'log_phone':
        case 'log_mail':
            document.getElementById('form_log_mail').classList.toggle('hidden');
            document.getElementById('form_log_phone').classList.toggle('hidden');
            break;

        case 'restore_phone':
        case 'restore_mail':
            document.getElementById('form_restore_by_email').classList.toggle('hidden');
            document.getElementById('form_restore_by_phone').classList.toggle('hidden');
            break;
    }
}

// Method for FormData take the form keys and values and put them in an object
export const objectFromFormData = (form) => {
    const values = {};
    for (let [key, value] of formDataEntries(form)) {
        if (values[key]) {
            if (!(values[key] instanceof Array)) {
                values[key] = new Array(values[key]);
            }
            values[key].push(value);
        } else {
            values[key] = value;
        }
    }
    return values;
}
